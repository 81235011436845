export enum VideoAttributeQueryType {
    'editQueryType' = 'editQueryType',
    'itemQueryType' = 'itemQueryType',
}
export const videoAttributes = {
    theme: {
        title: 'Theme',
        listQueryType: 'themeAttributes',
        [VideoAttributeQueryType.itemQueryType]: 'themeAttribute',
        [VideoAttributeQueryType.editQueryType]: 'editThemeAttribute',
    },
    category: {
        title: 'Category',
        listQueryType: 'categoryAttributes',
        [VideoAttributeQueryType.itemQueryType]: 'categoryAttribute',
        [VideoAttributeQueryType.editQueryType]: 'editCategoryAttribute',
    },
    type: {
        title: 'Video type',
        listQueryType: 'typeAttributes',
        [VideoAttributeQueryType.itemQueryType]: 'typeAttribute',
        [VideoAttributeQueryType.editQueryType]: 'editTypeAttribute',
    },
    uploadPolicy: {
        title: 'Upload policy',
        listQueryType: 'uploadPolicyAttributes',
        [VideoAttributeQueryType.itemQueryType]: 'uploadPolicyAttribute',
        [VideoAttributeQueryType.editQueryType]: 'editUploadPolicyAttribute',
    },
    playlistType: {
        title: 'Playlist type',
        listQueryType: 'playlistTypes',
        [VideoAttributeQueryType.itemQueryType]: 'playlistType',
        [VideoAttributeQueryType.editQueryType]: 'editPlaylistType',
    },
} as const;

export type VideoAttribute = keyof typeof videoAttributes;
export type VideoAttributeTypes = typeof videoAttributes[VideoAttribute];

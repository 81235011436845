import {Controller, FieldValues, Control} from 'react-hook-form';
import Box from '../../core/box/box';
import Switch from '../../core/form/switch/switch';
import * as styles from './switches.module.css';

type ISwitchesProps<T extends Record<string, any>> = {
    data: T[] | T,
    group: string,
    title?: string,
    control: Control<FieldValues>,
};

function Switches<T extends Record<string, any>>({
    data,
    control,
    group,
    title,
}: ISwitchesProps<T>) {
    const switches = data.map(({name, id}: T, idx: string) => (
        <Box key={`${name}-${idx}`} title={name}>
            <Controller
                render={(props: FieldValues) => (
                    <Switch
                        initialState={props.field.value?.includes(id)}
                        onChange={e => {
                            let newState;

                            if (e) {
                                newState = [...(props.field.value || []), id];
                            }

                            if (!e) {
                                newState = props.field.value?.filter((item: string) => item !== id);
                            }

                            props.field.onChange(newState);
                        }}
                    />
                )
                }
                control={control}
                name={group}
            />
        </Box>
    ));

    return (
        <div className={styles.wrapper}>
            {title && (
                <span className={styles.title}>
                    {title}
                </span>
            )}
            {data && switches}
        </div>
    );
}

export default Switches;

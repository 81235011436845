import {useState} from 'react';
import * as styles from './switch.module.css';

interface ISwitch {
    initialState: boolean,
    onChange: (e: boolean) => void,
}

const Switch = ({initialState = false, onChange}: ISwitch) => {
    const [active, setActive] = useState(initialState);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newState = e.target.checked;

        onChange(newState);
        setActive(newState);
    };

    return (
        <label className={styles.root}>
            <input
                type="checkbox"
                onChange={handleChange}
                defaultChecked={active}
            />
            <span className={styles.slider}/>
        </label>
    );
};

export default Switch;

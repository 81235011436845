import * as styles from './box.module.css';

interface IBoxProps {
    title: string,
    children?: any,
}

const Box = ({
    title,
    children,
}: IBoxProps) => (
    <div className={styles.wrapper}>
        <span className={styles.title}>
            {title}
        </span>
        {children && (
            <span className={styles.rightSide}>
                {children}
            </span>
        )}
    </div>
);

export default Box;
